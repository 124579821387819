.toggle-container {
    color: white;
    display: flex;
    position: relative;
    width: 100%;
    max-width: 300px;
    background: black;
    box-shadow: inset 0 0 0px 2px #ff5ee5;
    border-radius: 0.5rem;
    overflow: hidden;
}

.toggle-option {
    flex: 1;
    text-align: center;
    cursor: pointer;
    z-index: 1;
    user-select: none;
    transition: color 0.3s;
}

.toggle-slider {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: #ff5ee5;
    border-radius: 0.5rem;
    transition: transform 0.3s;
    z-index: 0;
}