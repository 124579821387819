.calculator-screen {
  background: #000;
}

.toggle-container {
  color: #fff;
  background: #000;
  border-radius: .5rem;
  width: 100%;
  max-width: 300px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 0 0 2px #ff5ee5;
}

.toggle-option {
  text-align: center;
  cursor: pointer;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  flex: 1;
  transition: color .3s;
}

.toggle-slider {
  z-index: 0;
  background: #ff5ee5;
  border-radius: .5rem;
  height: 100%;
  transition: transform .3s;
  position: absolute;
  top: 0;
  left: 0;
}

.input-error.input-error-show {
  z-index: 1;
  opacity: 1;
  transition: z-index, top, opacity .5s;
  top: calc(-1rem - 3px);
}

.input-error.input-error-hidden {
  z-index: -1;
  opacity: 0;
  transition: z-index, top, opacity .5s;
  top: 0;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.left-1\/2 {
  left: 50%;
}

.left-\[0\.575rem\] {
  left: .575rem;
}

.left-\[20\%\] {
  left: 20%;
}

.left-\[60\%\] {
  left: 60%;
}

.right-\[0\.575rem\] {
  right: .575rem;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.top-\[-5px\] {
  top: -5px;
}

.top-\[20\%\] {
  top: 20%;
}

.top-\[60\%\] {
  top: 60%;
}

.top-\[70\%\] {
  top: 70%;
}

.z-10 {
  z-index: 10;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mb-\[-1\.50px\] {
  margin-bottom: -1.5px;
}

.mb-\[-3\.00px\] {
  margin-bottom: -3px;
}

.mb-\[-6\.50px\] {
  margin-bottom: -6.5px;
}

.mb-\[-8\.00px\] {
  margin-bottom: -8px;
}

.ml-\[-1\.50px\] {
  margin-left: -1.5px;
}

.ml-\[-3\.00px\] {
  margin-left: -3px;
}

.ml-\[-60px\] {
  margin-left: -60px;
}

.mr-\[-1\.50px\] {
  margin-right: -1.5px;
}

.mr-\[-3\.00px\] {
  margin-right: -3px;
}

.mt-\[-1\.00px\] {
  margin-top: -1px;
}

.mt-\[-1\.50px\] {
  margin-top: -1.5px;
}

.mt-\[-3\.00px\] {
  margin-top: -3px;
}

.mt-\[-6\.50px\] {
  margin-top: -6.5px;
}

.mt-\[-8\.00px\] {
  margin-top: -8px;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-4 {
  height: 1rem;
}

.h-\[100vh\] {
  height: 100vh;
}

.h-\[42px\] {
  height: 42px;
}

.h-\[787px\] {
  height: 787px;
}

.h-\[8rem\] {
  height: 8rem;
}

.h-full {
  height: 100%;
}

.max-h-full {
  max-height: 100%;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-1\/5 {
  width: 20%;
}

.w-\[410px\] {
  width: 410px;
}

.w-\[424px\] {
  width: 424px;
}

.w-\[856px\] {
  width: 856px;
}

.w-\[8rem\] {
  width: 8rem;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-min {
  width: min-content;
}

.max-w-\[30rem\] {
  max-width: 30rem;
}

.flex-1 {
  flex: 1;
}

.flex-\[0_0_auto\] {
  flex: none;
}

.grow {
  flex-grow: 1;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-1 {
  gap: .25rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-2\.5 {
  gap: .625rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-\[23px\] {
  gap: 23px;
}

.gap-\[39px\] {
  gap: 39px;
}

.self-stretch {
  align-self: stretch;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-\[0\.5rem\] {
  border-radius: .5rem;
}

.rounded-\[15px\] {
  border-radius: 15px;
}

.rounded-\[20px\] {
  border-radius: 20px;
}

.rounded-\[30px\] {
  border-radius: 30px;
}

.rounded-\[50\%\] {
  border-radius: 50%;
}

.rounded-\[80px\] {
  border-radius: 80px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-\[2px\] {
  border-width: 2px;
}

.border-solid {
  border-style: solid;
}

.border-\[\#ff5ee5\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 94 229 / var(--tw-border-opacity));
}

.bg-\[\#0000003d\] {
  background-color: #0000003d;
}

.bg-\[\#ff5ee5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 94 229 / var(--tw-bg-opacity));
}

.bg-\[black\], .bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.p-2\.5 {
  padding: .625rem;
}

.p-4 {
  padding: 1rem;
}

.p-\[30px\] {
  padding: 30px;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-\[1rem\] {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-\[60px\] {
  padding-left: 60px;
  padding-right: 60px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.pl-\[70px\] {
  padding-left: 70px;
}

.pr-\[15px\] {
  padding-right: 15px;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-\[21px\] {
  font-size: 21px;
}

.text-\[22px\] {
  font-size: 22px;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-8, .leading-\[2rem\] {
  line-height: 2rem;
}

.leading-\[30px\] {
  line-height: 30px;
}

.leading-\[42px\] {
  line-height: 42px;
}

.leading-\[4rem\] {
  line-height: 4rem;
}

.leading-\[52px\] {
  line-height: 52px;
}

.leading-\[54px\] {
  line-height: 54px;
}

.leading-\[72px\] {
  line-height: 72px;
}

.tracking-\[0\] {
  letter-spacing: 0;
}

.text-\[white\] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.caret-\[\#ff5ee5\] {
  caret-color: #ff5ee5;
}

.shadow-\[0px_4px_4px_\#00000040\] {
  --tw-shadow: 0px 4px 4px #00000040;
  --tw-shadow-colored: 0px 4px 4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[inset_0_0_0_2px_\#ff5ee5\] {
  --tw-shadow: inset 0 0 0 2px #ff5ee5;
  --tw-shadow-colored: inset 0 0 0 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[inset_0_0_0_6px_\#ff5ee5\] {
  --tw-shadow: inset 0 0 0 6px #ff5ee5;
  --tw-shadow-colored: inset 0 0 0 6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.\[font-family\:\'Aeonik-Bold\'\,Helvetica\] {
  font-family: Aeonik-Bold, Helvetica;
}

.\[font-family\:\'Aeonik-Medium\'\,Helvetica\] {
  font-family: Aeonik-Medium, Helvetica;
}

.\[font-family\:\'Aeonik-Regular\'\,Helvetica\] {
  font-family: Aeonik-Regular, Helvetica;
}

.\[font-family\:\'Druk_Wide_Trial-Bold\'\,Helvetica\] {
  font-family: Druk Wide Trial-Bold, Helvetica;
}

@keyframes modal-video {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px);
  }

  to {
    transform: translate(0);
  }
}

.modal-video {
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -o-transition: opacity .3s ease-out;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  transition: opacity .3s ease-out;
  animation-name: modal-video;
  animation-duration: .3s;
  animation-timing-function: ease-out;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-video-effect-exit {
  opacity: 0;
}

.modal-video-effect-exit .modal-video-movie-wrap {
  transform: translate(0, 100px);
}

.modal-video-body {
  box-sizing: border-box;
  justify-content: center;
  width: 100%;
  max-width: 960px;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
}

.modal-video-inner {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

@media (orientation: landscape) {
  .modal-video-inner {
    box-sizing: border-box;
    padding: 10px 60px;
  }
}

.modal-video-movie-wrap {
  -webkit-transition: -webkit-transform .3s ease-out;
  -o-transition: -o-transform .3s ease-out;
  -moz-transition: -moz-transform .3s ease-out;
  -ms-transition: -ms-transform .3s ease-out;
  background-color: #333;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  transition: transform .3s ease-out;
  animation-name: modal-video-inner;
  animation-duration: .3s;
  animation-timing-function: ease-out;
  position: relative;
  transform: translate(0);
}

.modal-video-movie-wrap iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.modal-video-close-btn {
  z-index: 2;
  background: none;
  border: none;
  width: 35px;
  height: 35px;
  display: inline-block;
  position: absolute;
  top: -45px;
  right: 0;
  overflow: hidden;
}

@media (orientation: landscape) {
  .modal-video-close-btn {
    top: 0;
    right: -45px;
  }
}

.modal-video-close-btn:before {
  transform: rotate(45deg);
}

.modal-video-close-btn:after {
  transform: rotate(-45deg);
}

.modal-video-close-btn:before, .modal-video-close-btn:after {
  content: "";
  background: #fff;
  border-radius: 5px;
  width: 100%;
  height: 2px;
  margin-top: -6px;
  position: absolute;
  top: 50%;
  left: 0;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  all: unset;
}

input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.onboard .border-glow {
  animation: 2s ease-in infinite border-glow-animation;
  box-shadow: inset 0 0 0 2px #ff5ee5;
}

.onboard .onboarding-item {
  display: block;
}

.onboarding-item {
  display: none;
}

.calculator {
  position: relative;
}

.picked {
  box-shadow: inset 0 0 0 2px #ff5ee5, inset 0 0 1rem .25rem #ff5ee5 !important;
}

@keyframes border-glow-animation {
  15% {
    box-shadow: inset 0 0 0 2px #ff5ee5, inset 0 0 1rem .25rem #ff5ee5;
  }

  75% {
    box-shadow: inset 0 0 0 2px #ff5ee5, inset 0 0 1rem .25rem #ff5ee5;
  }

  80% {
    box-shadow: inset 0 0 0 2px #ff5ee5;
  }
}

.border {
  box-shadow: inset 0 0 0 2px #ff5ee5;
}

.calculator-screen {
  min-width: 100dvw;
  min-height: 100dvh;
  position: relative;
  overflow: hidden;
}

.background:before {
  content: "";
  z-index: 0;
  background-image: url("lugovska.merch-bg.2b692eee.png");
  background-size: 70px;
  width: 300%;
  height: 300%;
  animation: 30s linear infinite background-scroll;
  position: absolute;
  top: -100%;
  left: -100%;
  transform: rotate(30deg);
}

@keyframes background-scroll {
  100% {
    transform: translate(8%, 8%)rotate(30deg);
  }
}

@media (width >= 640px) {
  .sm\:relative {
    position: relative;
  }

  .sm\:order-1 {
    order: 1;
  }

  .sm\:order-2 {
    order: 2;
  }

  .sm\:ml-10 {
    margin-left: 2.5rem;
  }

  .sm\:h-40 {
    height: 10rem;
  }

  .sm\:h-fit {
    height: fit-content;
  }

  .sm\:max-h-full {
    max-height: 100%;
  }

  .sm\:w-40 {
    width: 10rem;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:min-w-\[30rem\] {
    min-width: 30rem;
  }

  .sm\:max-w-\[40\%\] {
    max-width: 40%;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:p-4 {
    padding: 1rem;
  }

  .sm\:text-\[1rem\] {
    font-size: 1rem;
  }

  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .sm\:leading-10 {
    line-height: 2.5rem;
  }

  .sm\:shadow-\[inset_0_0_0_0\.65rem_\#ff5ee5\] {
    --tw-shadow: inset 0 0 0 .65rem #ff5ee5;
    --tw-shadow-colored: inset 0 0 0 .65rem var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

@media (width >= 768px) {
  .md\:block {
    display: block;
  }

  .md\:hidden {
    display: none;
  }
}
/*# sourceMappingURL=index.1da48bc4.css.map */
