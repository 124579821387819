//global index.css
@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'node_modules/react-modal-video/scss/modal-video.scss';
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  all: unset;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.onboard .border-glow {
  animation: border-glow-animation 2s infinite ease-in;
  box-shadow: inset 0 0 0px 2px #ff5ee5;
}
.onboard .onboarding-item {
  display: block;
}
.onboarding-item {
  display: none;
}

.calculator {
  position: relative;
}
.picked {
  box-shadow: inset 0 0 0px 2px #ff5ee5, inset 0 0 1rem 0.25rem #ff5ee5 !important;
}

@keyframes border-glow-animation {
  15% {
    box-shadow: inset 0 0 0px 2px #ff5ee5, inset 0 0 1rem 0.25rem #ff5ee5;
  }
  75% {
    box-shadow: inset 0 0 0px 2px #ff5ee5, inset 0 0 1rem 0.25rem #ff5ee5;
  }
  80% {
    box-shadow: inset 0 0 0px 2px #ff5ee5;
  }
}

.border {
  box-shadow: inset 0 0 0px 2px #ff5ee5;
}
.calculator-screen {
  min-width: 100dvw;
  min-height: 100dvh;
  position: relative;
  overflow: hidden;
}

.background:before {
  content: "";
  position: absolute;
  width: 300%;
  height: 300%;
  top: -100%;
  left: -100%;
  z-index: 0;
  background-image: url('./lugovska.merch-bg.png');
  background-size: 70px;
  transform: rotate(30deg);
  animation: background-scroll 30s infinite linear;
  @keyframes background-scroll {
    100% {
      transform: translate(8%, 8%) rotate(30deg);
    }
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}