.input-error.input-error-show {
  top: calc(-1rem - 3px);
  transition: z-index, top, opacity 0.5s ease;
  z-index: 1;
  opacity: 1;
}

.input-error.input-error-hidden {
  transition: z-index, top, opacity 0.5s ease;
  top: 0;
  z-index: -1;
  opacity: 0;
}